export default [
  {
    title: 'Giriş',
    icon: 'HomeIcon',
    route: 'home',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Online Ödeme',
    icon: 'CreditCardIcon',
    route: 'Payment',
    resource: 'PAYMENT',
    action: 'read',
  },
  {
    title: 'Ödeme Geçmişi',
    icon: 'ArchiveIcon',
    route: 'PaymentHistory',
    resource: 'PAYMENT_HISTORY_LIST',
    action: 'read',
  },
  {
    title: 'Bize Ulaşın',
    icon: 'MapPinIcon',
    route: 'CompanyView',
    resource: 'COMPANY_VIEW',
    action: 'read',
  },
  {
    header: 'Kullanıcılar',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcı Listesi',
        route: 'Users',
        icon: 'UserIcon',
        resource: 'USERS_LIST',
        action: 'read',
      },
      {
        title: 'Kullanıcı Seviyeleri',
        route: 'UserTypes',
        icon: 'ShieldIcon',
        resource: 'USER_TYPES_LIST',
        action: 'read',
      },
    ],
  },
  {
    header: 'Ayarlar',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Banka Pos Ayarları',
        route: 'PosConfigs',
        icon: 'ServerIcon',
        resource: 'POS_CONFIGS_LIST',
        action: 'read',
      },
      {
        title: 'SMTP Ayarları',
        route: 'Smtpconfig',
        icon: 'MailIcon',
        resource: 'SMTP_CONFIG',
        action: 'read',
      },
      {
        title: 'Firma Bilgileri',
        route: 'Company',
        icon: 'BookIcon',
        resource: 'COMPANY_EDIT',
        action: 'read',
      },
    ],
  },
]
