<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="logo"
              alt="logo"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store'

export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/logo/app-logo-color.png'),
    }
  },
  computed: {
    logo() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.logoImg = require('@/assets/images/logo/app-logo-light.png')
        return this.logoImg
      }else{
        this.logoImg = require('@/assets/images/logo/app-logo-color.png')
      }
      return this.logoImg
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style scoped>
.brand-logo img{
  max-width: 160px !important;
}
</style>
